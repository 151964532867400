import { Amplify, I18n } from 'aws-amplify';
import { Authenticator, Text, View } from '@aws-amplify/ui-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/user-context';
import Account from './routes/account';
import awsconfig from './aws-exports';
import Cart from './routes/cart';
import Chats from './routes/chats';
import Checkout from './routes/checkout';
import CheckoutAddons from './routes/checkoutAddons';
import CheckoutSubscription from './routes/checkoutSubscription';
import { CustomerProvider } from './contexts/customer-context';
import Layout from './routes/layout';
import Leftovers from './routes/leftovers';
import Logo from './images/logo-nobg.png';
import { NotificationProvider } from './contexts/notification-context';
import { OrderProvider } from './contexts/order-context';
import PageNotFound from './routes/404';
import Payments from './routes/payments';
import Pricing from './routes/pricing';
import PropTypes from 'prop-types';
import React from 'react';
import Shipments from './routes/shipments';
import Store from './routes/store';
import Taxes from './routes/taxes';
import Users from './routes/users';
import './styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsconfig);
const components = {
    Header() {
        return (
            <a href="/">
                <img className="mx-auto" alt="" src={Logo} />
            </a>
        );
    },

    Footer() {
        return (
            <View textAlign="center" className="text-gray-500 mt-4">
                <Text>Sign ups are currently invite only</Text>
            </View>
        );
    }
};

I18n.putVocabulariesForLanguage('en', {
    Name: 'Business Name'
});

const ProtectedRoute = ({ children }) => {
    const { userGroup, userTier, userValidW9 } = useUser();

    if (userTier === 'initial') {
        return;
    }

    if (userGroup && userGroup.includes('admin')) {
        return children;
    }

    if (userValidW9 && userValidW9 !== 'approved') {
        return <Navigate to="/taxes" replace />;
    }

    if (!(userTier && userTier !== 'tier0')) {
        return <Navigate to="/pricing" replace />;
    }

    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.object
};

const SuperAdminRoute = ({ children }) => {
    const { userGroup } = useUser();
    if (userGroup && userGroup.includes('superadmin')) {
        return children;
    }

    return <Navigate to="/orders" replace />;
};

SuperAdminRoute.propTypes = {
    children: PropTypes.object
};

const NonEmployeeRoute = ({ children }) => {
    const { userGroup } = useUser();
    if (
        userGroup &&
        (userGroup.includes('superadmin') || userGroup.includes('client'))
    ) {
        return children;
    }

    return <Navigate to="/orders" replace />;
};

NonEmployeeRoute.propTypes = {
    children: PropTypes.object
};

const TaxesRoute = ({ children }) => {
    const { userValidW9 } = useUser();

    const hasValidW9 = userValidW9 && userValidW9 === 'approved';

    if (userValidW9 === 'initial') {
        return;
    }

    if (hasValidW9) {
        return <Navigate to="/pricing" replace />;
    }

    return children;
};

TaxesRoute.propTypes = {
    children: PropTypes.object
};

const PricingRoute = ({ children }) => {
    const { userValidW9 } = useUser();

    const hasValidW9 = userValidW9 && userValidW9 === 'approved';

    if (userValidW9 === 'initial') {
        return children;
    }

    if (!hasValidW9) {
        return <Navigate to="/taxes" replace />;
    }

    return children;
};

PricingRoute.propTypes = {
    children: PropTypes.object
};

function ProtectedApp() {
    return (
        <>
            <Authenticator components={components} hideSignUp={true}>
                <UserProvider>
                    <CustomerProvider>
                        <NotificationProvider>
                            <OrderProvider>
                                <Routes>
                                    {/* Routes with navbar */}
                                    <Route
                                        element={
                                            <ProtectedRoute>
                                                <Layout />
                                            </ProtectedRoute>
                                        }>
                                        <Route
                                            path="/account"
                                            element={<Account />}
                                        />
                                        <Route
                                            path="/chats"
                                            element={<Chats />}
                                        />
                                        <Route
                                            path="/store"
                                            element={
                                                <NonEmployeeRoute>
                                                    <Store />
                                                </NonEmployeeRoute>
                                            }
                                        />
                                        <Route
                                            path="/store/cart"
                                            element={
                                                <NonEmployeeRoute>
                                                    <Cart />
                                                </NonEmployeeRoute>
                                            }
                                        />
                                        <Route
                                            path="/payments"
                                            element={
                                                <NonEmployeeRoute>
                                                    <Payments />
                                                </NonEmployeeRoute>
                                            }
                                        />
                                        <Route
                                            path="/users"
                                            element={
                                                <SuperAdminRoute>
                                                    <Users />
                                                </SuperAdminRoute>
                                            }
                                        />
                                        <Route
                                            path="/leftovers"
                                            element={<Leftovers />}
                                        />
                                        <Route
                                            path="/orders"
                                            element={<Shipments />}
                                        />
                                        <Route
                                            path="*"
                                            element={<PageNotFound />}
                                        />
                                    </Route>
                                    {/* Routes without navbar */}
                                    <Route
                                        path="/pricing"
                                        element={
                                            <PricingRoute>
                                                <Pricing />
                                            </PricingRoute>
                                        }
                                    />
                                    <Route
                                        path="/checkout"
                                        element={<Checkout />}
                                    />
                                    <Route
                                        path="/checkoutSubscription"
                                        element={<CheckoutSubscription />}
                                    />
                                    <Route
                                        path="/checkoutAddons"
                                        element={<CheckoutAddons />}
                                    />
                                    <Route
                                        path="/taxes"
                                        element={
                                            <TaxesRoute>
                                                <Taxes />
                                            </TaxesRoute>
                                        }
                                    />
                                </Routes>
                            </OrderProvider>
                        </NotificationProvider>
                    </CustomerProvider>
                </UserProvider>
            </Authenticator>
        </>
    );
}

export default ProtectedApp;
