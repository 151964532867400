import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import getTierFromUnits from '../utils/getTierFromUnits';
import { IconArrowUpCircle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import listUsers from '../utils/listUsers';
import PaymentCardPaid from '../components/payment-card-paid';
import PaymentCardUnpaid from '../components/payment-card-unpaid';
import PaymentsSkeleton from '../components/skeletons/payments-skeleton';
import { useUser } from '../contexts/user-context';

const unitTotalsByTier = {
    1: '2000',
    2: '5000',
    3: '8000',
    4: '8000+'
};

function Payments() {
    const [addons, setAddons] = useState([]);
    const [addonReceipts, setAddonReceipts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [showPaid, setShowPaid] = useState(false);
    const [users, setUsers] = useState([]);
    const { user, userGroup } = useUser();

    // Units for current user
    const [unitCount, setUnitCount] = useState('0');

    // Total units for tier
    const [unitTotal, setUnitTotal] = useState('0');

    // Cache for Stripe units
    const unitsCache = new Map();

    const selectedButtonClass =
        'underline text-lg font-semibold underline-offset-1 decoration-2';
    const unselectedButtonClass =
        'text-lg font-semibold underline-offset-1 decoration-2';

    const handleGetAddons = async () => {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        const token = amplifyUser.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };
        API.get('myamzprepfixapi', '/addons', requestInfo).then(response => {
            setAddons(response.Items);
        });
    };

    const setAddonsPaid = async order => {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        const token = amplifyUser.signInUserSession.idToken.jwtToken;
        const updateRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                order: {
                    OrderID: order.OrderID,
                    UserID: order.UserID,
                    AddonsPaid: true,
                    Status: order.Status
                }
            }
        };
        console.log(updateRequestInfo);
        API.patch('myamzprepfixapi', '/orders', updateRequestInfo)
            .then(() => {
                const newOrders = orders.filter(
                    orderItem => orderItem.OrderID !== order.OrderID
                );
                setOrders(newOrders);
            })
            .catch(error => {
                console.log(error);
            });
    };
    const callApi = async () => {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        const user2 = await Auth.currentSession();

        const token = amplifyUser.signInUserSession.idToken.jwtToken;
        const token2 = user2.getAccessToken().getJwtToken();
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };

        if (userGroup.includes('admin')) {
            setUsers(await listUsers('client', token2));
        }

        // this is how you get receipts for addon payments
        API.get('MyAMZPrepStoreAPI', '/store/addonReceipts', requestInfo)
            .then(response => {
                console.log('addon receipts', response);
                setAddonReceipts(response.Items);
            })
            .catch(error => {
                console.log(error);
            });

        try {
            const openOrdersResponse = await API.get(
                'myamzprepfixapi',
                '/orders',
                requestInfo
            );
            const openOrders = openOrdersResponse.Items.map(item => {
                item.id = item.OrderID;
                if (item.nodes && item.nodes.length > 0) {
                    item.nodes.forEach(node => {
                        node.id = node.ProductID;
                    });
                }
                return item;
            });

            setOrders(openOrders);
            console.log(openOrdersResponse);
        } catch (error) {
            console.log(error);
            setLoading(false);
            return; // Stop further execution if open orders fetching fails
        }

        // Fetch shipped orders with pagination
        let allShippedOrders = [];

        const fetchShippedOrders = async (ExclusiveStartKey = null) => {
            const queryParams = ExclusiveStartKey
                ? `?ExclusiveStartKey=${encodeURIComponent(
                      JSON.stringify(ExclusiveStartKey)
                  )}`
                : '?ExclusiveStartKey=null';
            const route = '/orders' + queryParams;

            try {
                const response = await API.get(
                    'myamzprepfixapi',
                    route,
                    requestInfo
                );
                const shippedOrders = response.Items.map(item => {
                    item.id = item.OrderID;
                    if (item.nodes && item.nodes.length > 0) {
                        item.nodes.forEach(node => {
                            node.id = node.ProductID;
                        });
                    }
                    return item;
                });

                allShippedOrders = [...allShippedOrders, ...shippedOrders];
                const sortedOrders = allShippedOrders.sort((a, b) => {
                    const dateA = new Date(a.CreatedAt);
                    const dateB = new Date(b.CreatedAt);
                    return dateB - dateA;
                });

                setOrders([...orders, ...sortedOrders]);

                if (response.LastEvaluatedKey) {
                    await fetchShippedOrders(response.LastEvaluatedKey);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        // Initial fetch for shipped orders
        await fetchShippedOrders();

        if (unitsCache.has(user['custom:stripeCustomerId'])) {
            setUnitCount(unitsCache.get(user['custom:stripeCustomerId']));
        } else {
            API.get(
                'MyAMZPrepStoreAPI',
                '/store/subscription',
                requestInfo
            ).then(res => {
                unitsCache.set(
                    user['custom:stripeCustomerId'],
                    res.usageRecords.data[0].total_usage
                );
                setUnitCount(res.usageRecords.data[0].total_usage);
            });
        }
    };

    useEffect(() => {
        setLoading(true);
        setUnitTotal(unitTotalsByTier[getTierFromUnits(unitCount)]);
        callApi();
        handleGetAddons();
    }, []);

    // Determines if an order should be displayed as a payment
    const isOrderPayment = order => {
        if (order.AddonsPaid && !showPaid) {
            return false;
        } else if (!order.AddonsPaid && showPaid) {
            return false;
        }

        let hasAddons = false;
        if (
            order.Status !== 'Awaiting Carrier Pickup' &&
            order.Status !== 'Picked Up By Carrier'
        ) {
            return false;
        }
        if (order.nodes) {
            order.nodes?.forEach(product => {
                if (product.addons && product.addons.length > 0) {
                    hasAddons = true;
                }
            });
        }

        return hasAddons;
    };

    const PaymentsList = () => {
        if (orders.length > 0) {
            return orders.map(order =>
                isOrderPayment(order) ? (
                    !order.AddonsPaid ? (
                        <PaymentCardUnpaid
                            key={order.id}
                            order={order}
                            addons={addons}
                            users={users}
                            userUnits={unitCount}
                            unitsCache={unitsCache}
                            setAddonsPaid={setAddonsPaid}
                        />
                    ) : (
                        <PaymentCardPaid
                            key={order.id}
                            order={order}
                            receipts={addonReceipts}
                            addons={addons}
                        />
                    )
                ) : (
                    <></>
                )
            );
        }
    };

    return (
        <div className="w-full relative px-4 lg:px-0">
            <div className="mt-4 lg:mt-10 mb-6 ml-2 lg:ml-9">
                <h1 className="text-3xl font-bold text-gray-900">Payments</h1>
                <p className="mt-1.5 text-base sm:text-sm text-gray-500">
                    Make payments and see history.
                </p>
            </div>
            <div className="absolute right-8 top-8">
                {!userGroup.includes('admin') && (
                    <div className="flex">
                        <div className="mr-6 mt-2">
                            <span className="mr-3 font-bold">
                                Tier {getTierFromUnits(unitCount)}:
                            </span>
                            <span>{`Units: ${unitCount}/${unitTotal}`}</span>
                        </div>
                        <div>
                            <Link
                                to="/pricing"
                                className="w-full lg:w-44 h-[42px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                <IconArrowUpCircle className="mr-1 -ml-1" />
                                Upgrade tier
                            </Link>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-center mt-4 mb-4">
                <div className="flex mr-16">
                    <button
                        onClick={() => setShowPaid(false)}
                        className={
                            showPaid
                                ? unselectedButtonClass
                                : selectedButtonClass
                        }>
                        Unpaid orders
                    </button>
                </div>
                <div className="flex ml-16">
                    <button
                        onClick={() => setShowPaid(true)}
                        className={
                            showPaid
                                ? selectedButtonClass
                                : unselectedButtonClass
                        }>
                        Paid orders
                    </button>
                </div>
            </div>
            {loading ? (
                <PaymentsSkeleton />
            ) : (
                <div className="flex flex-wrap items-start justify-evenly">
                    <PaymentsList />
                </div>
            )}
        </div>
    );
}

export default Payments;
